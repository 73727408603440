import React, { useContext, useState } from "react";
import { Box, InputLabel, TextField, Typography, Button } from "@mui/material";
import axios from "axios";
import { Store } from "../Store";
import { useNavigate } from "react-router-dom";

const labelStyles = { mb: 1, mt: 2, fontSize: "24px", fontWeight: "bold" };

const AddProject = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [program, setProgram] = useState("");
  const [surface, setSurface] = useState("");
  const [location, setLocation] = useState("");
  const [projectStatus, setProjectStatus] = useState("");
  const [year, setYear] = useState("");
  const [priority, setPriority] = useState("");
  const [investor, setInvestor] = useState("");
  const [team, setTeam] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState("");

  const sendRequest = async (formData) => {
    const res = await axios
      .post("https://api.aghayev-marjanscy.pl/api/projects/add", formData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("title", title);
    formData.append("program", program);
    formData.append("surface", surface);
    formData.append("location", location);
    formData.append("projectStatus", projectStatus);
    formData.append("year", year);
    formData.append("priority", priority);
    formData.append("investor", investor);
    formData.append("team", team);
    formData.append("category", category);
    formData.append("description", description);
    for (const key of Object.keys(images)) {
      formData.append("images", images[key]);
    }
    try {
      sendRequest(formData).then(navigate("/"));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box
          border={3}
          borderColor="black"
          borderRadius={10}
          boxShadow="10px 10px 20px #ccc"
          padding={3}
          margin={"auto"}
          marginTop={3}
          display="flex"
          flexDirection={"column"}
          width={"80%"}
        >
          <Typography
            fontWeight={"bold"}
            padding={3}
            color="grey"
            variant="h6"
            textAlign={"center"}
          >
            Dodaj Projekt
          </Typography>
          <InputLabel sx={labelStyles}>Tytuł</InputLabel>
          <TextField
            name="title"
            onChange={(e) => setTitle(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Program</InputLabel>
          <TextField
            name="program"
            onChange={(e) => setProgram(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Powierzchnia</InputLabel>
          <TextField
            name="surface"
            onChange={(e) => setSurface(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Lokalizacja</InputLabel>
          <TextField
            name="location"
            onChange={(e) => setLocation(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Status</InputLabel>
          <TextField
            name="projectStatus"
            onChange={(e) => setProjectStatus(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Rok</InputLabel>
          <TextField
            name="year"
            onChange={(e) => setYear(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Priorytet</InputLabel>
          <TextField
            name="priority"
            onChange={(e) => setPriority(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Inwestor</InputLabel>
          <TextField
            name="investor"
            onChange={(e) => setInvestor(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Zespół</InputLabel>
          <TextField
            name="team"
            onChange={(e) => setTeam(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Kategoria</InputLabel>
          <TextField
            name="category"
            onChange={(e) => setCategory(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
          <InputLabel sx={labelStyles}>Opis</InputLabel>
          <TextField
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            required
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
          />
          <InputLabel sx={labelStyles}>Zdjęcia</InputLabel>
          <input
            accept="image/*"
            name="images"
            type="file"
            onChange={(e) => setImages(e.target.files)}
            required
            margin="normal"
            variant="outlined"
            multiple
          />
          <Button
            sx={{ mt: 2, borderRadius: 4 }}
            variant="contained"
            type="submit"
          >
            Dodaj
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default AddProject;
