import Header from "./components/Header";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Projects from "./pages/Projects";
import AddProject from "./pages/AddProject";
import EditProject from "./pages/EditProject";
import ProjectDetails from "./pages/ProjectDetails";
import ContactInfo from "./pages/ContactInfo";
import AboutUs from "./pages/AboutUs";
import ProtectedRoute from "./components/ProtectedRoute";
import Category from "./pages/Category";

function App() {
  return (
    <React.Fragment>
      <header>
        <Header />
      </header>
      <main>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetails />} />
          <Route path="/contact" element={<ContactInfo />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/search" element={<Category />} />
          <Route
            path="/projects/add"
            element={
              <ProtectedRoute>
                <AddProject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/projects/edit/:id"
            element={
              <ProtectedRoute>
                <EditProject />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
    </React.Fragment>
  );
}

export default App;
