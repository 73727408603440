import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Card, ImageListItemBar } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const Projects = () => {
  const [projects, setProjects] = useState();

  const getProjects = async () => {
    const res = await axios
      .get("https://api.aghayev-marjanscy.pl/api/projects")
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };

  useEffect(() => {
    getProjects().then((data) => setProjects(data.projects));
  }, []);

  return (
    <div className="base">
      <ImageList gap={12} className="image-list-grid-template">
        {projects &&
          projects.map((project) => (
            <Card key={project._id} style={{ maxHeight: "550px" }}>
              <Link
                className="check"
                to={`/projects/${project._id}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ImageListItem
                  className="showTitle"
                  key={project.title}
                  sx={{ height: "100% !important", width: "100% !important" }}
                >
                  <img
                    // className="gallery-image"
                    src={`${project.images[0]}`}
                    alt={project.title}
                    loading="lazy"
                    style={{ cursor: "pointer" }}
                  />
                  <ImageListItemBar
                    className="showProjectTitle"
                    title={project.title}
                  />
                </ImageListItem>
              </Link>
            </Card>
          ))}
      </ImageList>
    </div>
  );
};

export default Projects;
