import React from "react";
import { Typography, Stack } from "@mui/material";

const AboutUs = () => {
  return (
    <div className="base">
      <Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          display="flex"
          spacing={6}
          paddingBottom={"60px"}
        >
          <Stack>
            <Typography textAlign="left" paddingBottom={"10px"}>
              O NAS
            </Typography>
            <Typography textAlign="justify" paddingBottom={"10px"}>
              AGHAYEV I MARJAŃSCY to dwie współpracujące pracownie
              architektoniczne, które poza więzami rodzinnymi połączyła wspólna
              pasja do architektury.
            </Typography>
            <Typography textAlign="justify" paddingBottom={"10px"}>
              Nasza współpraca łączy w sobie wieloletnie doświadczenie, wiedzę
              techniczną i skuteczność w realizowaniu zleceń, z ambitnym i
              nowatorskim spojrzeniem na projekty.
            </Typography>
            <Typography textAlign="justify" paddingBottom={"30px"}>
              Zajmujemy się projektami w każdej skali zarówno w sektorze
              publicznym jak i prywatnym. Nie boimy się wyzwań i w każdym
              wypadku oferujemy pełne zaangażowanie i rzetelność w wykonaniu
              projektu, tak aby efektem końcowym był funkcjonalny i piękny
              obiekt.
            </Typography>
            <Typography textAlign="left" paddingBottom={"10px"}>
              ZAKRES DZIAŁALNOŚCI FIRM
            </Typography>
            <Typography textAlign="left">
              • pełnobranżowa obsługa inwestycji
            </Typography>
            <Typography textAlign="left">
              • projekty zagospodarowania terenu
            </Typography>
            <Typography textAlign="left">
              • projekty architektoniczno-budowlane wielobranżowe
            </Typography>
            <Typography textAlign="left">
              • projekty techniczne wielobranżowe
            </Typography>
            <Typography textAlign="left">
              • projekty architektoniczno-konserwatorskie dla obiektów objętych
              ochroną konserwatorską
            </Typography>
            <Typography textAlign="left">
              • projekty aranżacji wnętrz
            </Typography>
            <Typography textAlign="left">• projekty rozbiórek</Typography>
            <Typography textAlign="left">
              • analizy uwarunkowań terenów inwestycyjnych
            </Typography>
            <Typography textAlign="left">
              • analizy chłonności terenów inwestycyjnych ze wstępnymi
              koncepcjami zabudowy
            </Typography>
            <Typography textAlign="left">
              • koncepcje programowo-przestrzenne i programy
              funkcjonalno-użytkowe,
            </Typography>
            <Typography textAlign="left">
              • koncepcje architektoniczne, w tym wizualizacje
            </Typography>
            <Typography textAlign="left">
              • prowadzenie postępowań administracyjnych mających na celu
              uzyskanie:
            </Typography>
            <Typography textAlign="left" paddingLeft={"20px"}>
              - decyzji o warunkach zabudowy
            </Typography>
            <Typography textAlign="left" paddingLeft={"20px"}>
              - decyzji o środowiskowych uwarunkowaniach
            </Typography>
            <Typography textAlign="left" paddingLeft={"20px"}>
              - decyzji pozwolenia na budowę
            </Typography>
            <Typography textAlign="left" paddingLeft={"20px"}>
              - pozwolenia na rozbiórkę, decyzji i opinii konserwatorskich
            </Typography>
            <Typography textAlign="left" paddingLeft={"20px"}>
              - zgłoszenia zamiaru wykonania robót budowlanych
            </Typography>
            <Typography textAlign="left">
              • nadzór autorski nad realizacją
            </Typography>
          </Stack>
          <Stack>
            <img src="/IMG_1.jpg" alt="aboutUs" className="img-large" />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          paddingBottom={"24px"}
        >
          <Stack>
            <img src="/person.png" alt="aboutUs" className="person-image" />
            <Typography
              className="person-description"
              paddingTop={"20px"}
              paddingBottom={"16px"}
              textAlign="justify"
              fontWeight={"bold"}
            >
              mgr inż. architekt Farid Agajew
            </Typography>
            <Typography
              className="person-description"
              paddingBottom={"16px"}
              textAlign="justify"
            >
              Współzałożyciel biura AGHAYEV PRACOWNIA PROJEKTOWA, absolwent
              Politechniki Krakowskiej na Wydziale Architektury oraz Akademii
              Sztuk Pięknych w Baku. Architekt z 30-letnim doświadczeniem
              zawodowym. Posiada uprawnienia do projektowania w specjalności
              architektura bez ograniczeń a także uprawnienia budowlane do
              kierowania robotami budowlanym.
            </Typography>
          </Stack>
          <Stack>
            <img src="/person.png" alt="aboutUs" className="person-image" />
            <Typography
              className="person-description"
              paddingTop={"20px"}
              paddingBottom={"16px"}
              textAlign="justify"
              fontWeight={"bold"}
            >
              dr inż. architekt Maria Agajew
            </Typography>
            <Typography
              className="person-description"
              paddingBottom={"16px"}
              textAlign="justify"
            >
              Współzałożycielka biura AGHAYEV PRACOWNIA PROJEKTOWA, absolwentka
              Politechniki Krakowskiej na Wydziale Architektury. Wykładowca w
              Instytucie Architektury i Urbanistyki WBAIŚ Politechniki Łódzkiej.
              Architekt z ponad 25-letnim doświadczeniem zawodowym. Posiada
              uprawnienia do projektowania w specjalności architektura bez
              ograniczeń.
            </Typography>
          </Stack>
          <Stack>
            <img src="/person.png" alt="aboutUs" className="person-image" />
            <Typography
              className="person-description"
              paddingTop={"20px"}
              paddingBottom={"16px"}
              textAlign="justify"
              fontWeight={"bold"}
            >
              mgr inż. architekt Paweł Marjański
            </Typography>
            <Typography
              className="person-description"
              paddingBottom={"16px"}
              textAlign="justify"
            >
              Współzałożyciel biura MARJAŃSCY PRACOWNIA PROJEKTOWA, absolwent
              Politechniki Łódzkiej na wydziale Budownictwa, Architektury i
              Inżynierii Środowiska na kierunku Architektura. W trakcie studiów
              uczestniczył w wymianie studenckiej z University of Miami w USA.
              Doświadczenie zawodowe zdobywał w Polsce oraz Hiszpanii w pracowni
              Bjarke Ingels Group, a także przy współpracy z Atelier Entropic.
            </Typography>
          </Stack>
          {/* <Stack>
            <img src="/person.png" alt="aboutUs" className="person-image" />
            <Typography
              className="person-description"
              paddingTop={"20px"}
              paddingBottom={"16px"}
              textAlign="justify"
              fontWeight={"bold"}
            >
              mgr inż. architekt Sima Marjańska
            </Typography>
            <Typography
              className="person-description"
              paddingBottom={"16px"}
              textAlign="justify"
            >
              Współzałożycielka biura MARJAŃSCY PRACOWNIA PROJEKTOWA,
              absolwentka w Politechniki Łódzkiej na wydziale Budownictwa,
              Architektury i Inżynierii Środowiska na kierunku Architektura. W
              trakcie studiów uczestniczyła w wymianach studenckich z Hochschule
              Mainz - University of Applied Sciences w Niemczech oraz University
              of Ljubljana na Słowenii. W latach 2018/2019 pracowała w
              Architekturinstitut der Hochschule Mainz, zajmując się
              rekonstrukcją zabytków architektonicznych w technologii 3D.
              Doświadczenie zdobywała również pracując pod okiem pracowni
              AGHAYEV.
            </Typography>
          </Stack> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default AboutUs;
