import { useContext, useEffect, useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Store } from "../Store";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "https://api.aghayev-marjanscy.pl/api/user/login",
        {
          email,
          password,
        }
      );
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userInfo) {
      // console.log("userInfo");
      // console.log(userInfo);
      navigate("/");
    }
  }, [navigate, userInfo]);

  return (
    <Container>
      <form onSubmit={submitHandler}>
        <Box
          maxWidth={400}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          boxShadow="10px 10px 20px #ccc"
          padding={3}
          margin="auto"
          marginTop={5}
          borderRadius={5}
        >
          <Typography variant="h3" padding={3} textAlign="center">
            Logowanie
          </Typography>
          <TextField
            name="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            type={"email"}
            placeholder="Email"
            margin="normal"
          />
          <TextField
            name="password"
            required
            onChange={(e) => setPassword(e.target.value)}
            type={"password"}
            placeholder="Password"
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: 3, marginTop: 3 }}
          >
            Zaloguj
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default Login;
