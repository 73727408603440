import React from "react";
import { Box, Typography, Stack } from "@mui/material";

const ContactInfo = () => {
  return (
    <div className="base">
      <Typography textAlign="left">KONTAKT</Typography>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Stack paddingBottom={"16px"}>
          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Stack>
              <Typography textAlign="left" paddingRight={"80px"}>
                Adres:
              </Typography>
            </Stack>
            <Stack>
              <Typography textAlign="left">ul.Rewolucji 1905 R.</Typography>
              <Typography textAlign="left">82 m.D25 90-223 Łódź</Typography>
            </Stack>
          </Stack>
          <Stack paddingTop={"16px"}>
            <Typography textAlign="left">Farid Agajew</Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Stack>
              <Typography textAlign="left" paddingRight={"70px"}>
                Telefon:
              </Typography>
              <Typography textAlign="left" paddingRight={"70px"}>
                Email:
              </Typography>
            </Stack>
            <Stack>
              <Typography textAlign="left">+48 698 002 363</Typography>
              <Typography textAlign="left">farid.aghayev@wp.pl</Typography>
            </Stack>
          </Stack>
          <Box paddingTop={"16px"}>
            <Typography textAlign="left">Paweł Marjański</Typography>
          </Box>
          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Stack>
              <Typography textAlign="left" paddingRight={"70px"}>
                Telefon:
              </Typography>
              <Typography textAlign="left" paddingRight={"70px"}>
                Email:
              </Typography>
            </Stack>
            <Stack>
              <Typography textAlign="left">+48 531 199 469</Typography>
              <Typography textAlign="left">pm@marjanscy.pl</Typography>
            </Stack>
          </Stack>

          {/* <Box paddingTop={"16px"}>
            <Typography textAlign="left">Sima Marjańska</Typography>
          </Box>
          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Stack>
              <Typography textAlign="left" paddingRight={"70px"}>
                Telefon:
              </Typography>
              <Typography textAlign="left" paddingRight={"70px"}>
                Email:
              </Typography>
            </Stack>
            <Stack>
              <Typography textAlign="left">+48 694 558 817</Typography>
              <Typography textAlign="left">sm@marjanscy.pl</Typography>
            </Stack>
          </Stack> */}
        </Stack>
        <Stack paddingBottom={"16px"}>
          <Typography textAlign="left">
            AGHAYEV PRACOWNIA PROJEKTOWA FARID AGAJEW
          </Typography>
          <Typography textAlign="left">
            90-223 Łódź ul. Rewolucji 1905 R. 82 m.D25
          </Typography>
          <Typography textAlign="left">NIP: 7251485671</Typography>
          <Typography textAlign="left" marginBottom={"13%"}>
            REGON: 473276922
          </Typography>
          <Typography textAlign="left">
            MARJAŃSCY PRACOWNIA PROJEKTOWA Paweł Marjański
          </Typography>
          <Typography textAlign="left">
            91-078 Łódź ul. Kasprzaka 59 m.36
          </Typography>
          <Typography textAlign="left">NIP: 7272860938</Typography>
          <Typography textAlign="left">Regon: 522640511</Typography>
        </Stack>
        <Stack paddingBottom={"16px"}>
          <iframe
            className="map"
            title="Marjanscy_location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1234.3042160930536!2d19.473196195514323!3d51.77676519984421!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x849d73f48c6e2479!2sAghayev%20Marja%C5%84scy%20Pracownia%20Projektowa!5e0!3m2!1spl!2spl!4v1674165361548!5m2!1spl!2spl"
            height="100%"
            width="height"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Stack>
      </Stack>
    </div>
  );
};

export default ContactInfo;
