import React, { useContext, useState, useEffect } from "react";
import { Store } from "../Store";
import { AppBar, Toolbar, Box, Typography, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Header = () => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [categories, setCategories] = useState();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCategories = async () => {
    const res = await axios
      .get("https://api.aghayev-marjanscy.pl/api/projects/categories")
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };

  useEffect(() => {
    getCategories().then((data) => setCategories(data.categories));
  }, []);

  return (
    <div>
      <AppBar elevation={0} position="fixed" sx={{ background: "white" }}>
        <Toolbar sx={{ paddingTop: 3 }}>
          <Link to={"/"} style={{ textDecoration: "none", color: "black" }}>
            <Box
              component="img"
              sx={{ height: 100 }}
              alt="Marjanscy"
              src="/logo_final.png"
            />
          </Link>
          <Box className="header-menu-box">
            {userInfo && userInfo.isAdmin && (
              <Link
                to={"/projects/add"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button sx={{ color: "#000" }}>Dodaj Projekt</Button>
              </Link>
            )}
            {!userInfo && (
              <Link
                to={"/login"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Typography variant="h6" color="white">
                  Z
                </Typography>
              </Link>
            )}
            {userInfo && (
              <Link to={"/"} style={{ textDecoration: "none", color: "black" }}>
                <Button onClick={signoutHandler} sx={{ color: "#000" }}>
                  Wyloguj
                </Button>
              </Link>
            )}
            <Stack
              className="stack-menu"
              sx={{
                flexWrap: "wrap",
              }}
            >
              <Button
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "#000" }}
              >
                Projekty
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Link
                  to={"/"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleClose}>
                    <Typography textAlign="center">Wszystkie</Typography>
                  </MenuItem>
                </Link>
                {categories &&
                  categories.map((category) => (
                    <Link
                      to={`/search?category=${category}`}
                      style={{ textDecoration: "none", color: "black" }}
                      key={category}
                    >
                      <MenuItem onClick={handleClose} key={category}>
                        <Typography textAlign="center" key={category}>
                          {category}
                        </Typography>
                      </MenuItem>
                    </Link>
                  ))}
              </Menu>
              <Link
                to={"/aboutus"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button sx={{ color: "#000" }}>O nas</Button>
              </Link>
              <Link
                to={"/contact"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button sx={{ color: "#000" }}>Kontakt</Button>
              </Link>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ paddingTop: 10 }} />
    </div>
  );
};

export default Header;
