import React, { useContext, useState, useEffect } from "react";
import { Box, InputLabel, TextField, Typography, Button } from "@mui/material";
import axios from "axios";
import { Store } from "../Store";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const labelStyles = { mb: 1, mt: 2, fontSize: "24px", fontWeight: "bold" };

const EditProject = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const params = useParams();
  const [project, setProject] = useState({
    id: "",
    title: "",
    program: "",
    surface: "",
    location: "",
    projectStatus: "",
    year: 0,
    priority: 0,
    investor: "",
    team: "",
    category: "",
    description: "",
  });

  const { id } = params;

  const sendRequest = async (reqData) => {
    const res = await axios
      .put(
        `https://api.aghayev-marjanscy.pl/api/projects/update/${id}`,
        reqData,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };

  useEffect(() => {
    const getProject = async () => {
      const res = await axios
        .get(`https://api.aghayev-marjanscy.pl/api/projects/${id}`)
        .catch((err) => console.log(err));
      const data = await res.data;
      return data;
    };
    getProject().then((data) => setProject(data.project));
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("title", project?.title);
    formData.append("program", project?.program);
    formData.append("surface", project?.surface);
    formData.append("location", project?.location);
    formData.append("projectStatus", project?.projectStatus);
    formData.append("year", project?.year);
    formData.append("priority", project?.priority);
    formData.append("investor", project?.investor);
    formData.append("team", project?.team);
    formData.append("category", project?.category);
    formData.append("description", project?.description);
    formData.append("images", project?.images);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));
    try {
      sendRequest(data).then(navigate("/"));
    } catch (err) {
      console.log(err);
    }
  };

  function handleTitleChange(e) {
    setProject({
      ...project,
      title: e.target.value,
    });
  }

  function handleProgramChange(e) {
    setProject({
      ...project,
      program: e.target.value,
    });
  }

  function handleSurfaceChange(e) {
    setProject({
      ...project,
      surface: e.target.value,
    });
  }

  function handleLocationChange(e) {
    setProject({
      ...project,
      location: e.target.value,
    });
  }

  function handleProjectStatusChange(e) {
    setProject({
      ...project,
      projectStatus: e.target.value,
    });
  }

  function handleYearChange(e) {
    setProject({
      ...project,
      year: e.target.value,
    });
  }

  function handlePriorityChange(e) {
    setProject({
      ...project,
      priority: e.target.value,
    });
  }

  function handleInvestorChange(e) {
    setProject({
      ...project,
      investor: e.target.value,
    });
  }

  function handleTeamChange(e) {
    setProject({
      ...project,
      team: e.target.value,
    });
  }

  function handleCategoryChange(e) {
    setProject({
      ...project,
      category: e.target.value,
    });
  }

  function handleDescriptionChange(e) {
    setProject({
      ...project,
      description: e.target.value,
    });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {project && (
          <Box
            border={3}
            borderColor="black"
            borderRadius={10}
            boxShadow="10px 10px 20px #ccc"
            padding={3}
            margin={"auto"}
            marginTop={3}
            display="flex"
            flexDirection={"column"}
            width={"80%"}
          >
            <Typography
              fontWeight={"bold"}
              padding={3}
              color="grey"
              variant="h6"
              textAlign={"center"}
            >
              Edytuj Projekt - {project?.title}
            </Typography>
            <InputLabel sx={labelStyles}>Tytuł</InputLabel>
            <TextField
              name="title"
              value={project?.title}
              onChange={handleTitleChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Program</InputLabel>
            <TextField
              name="program"
              value={project?.program}
              onChange={handleProgramChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Powierzchnia</InputLabel>
            <TextField
              name="surface"
              value={project?.surface}
              onChange={handleSurfaceChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Lokalizacja</InputLabel>
            <TextField
              name="location"
              value={project?.location}
              onChange={handleLocationChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Status</InputLabel>
            <TextField
              name="projectStatus"
              value={project?.projectStatus}
              onChange={handleProjectStatusChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Rok</InputLabel>
            <TextField
              name="year"
              value={project?.year}
              onChange={handleYearChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Priorytet</InputLabel>
            <TextField
              name="priority"
              value={project?.priority}
              onChange={handlePriorityChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Inwestor</InputLabel>
            <TextField
              name="investor"
              value={project?.investor}
              onChange={handleInvestorChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Zespół</InputLabel>
            <TextField
              name="team"
              value={project?.team}
              onChange={handleTeamChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Kategoria</InputLabel>
            <TextField
              name="category"
              value={project?.category}
              onChange={handleCategoryChange}
              required
              margin="normal"
              variant="outlined"
            />
            <InputLabel sx={labelStyles}>Opis</InputLabel>
            <TextField
              name="description"
              value={project?.description}
              onChange={handleDescriptionChange}
              required
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <Button
              sx={{ mt: 2, borderRadius: 4 }}
              variant="contained"
              type="submit"
            >
              Edytuj
            </Button>
          </Box>
        )}
      </form>
    </div>
  );
};

export default EditProject;
