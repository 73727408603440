import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ImageList,
  ImageListItem,
  Card,
  ImageListItemBar,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Category = () => {
  const [projects, setProjects] = useState();
  const { search } = useLocation();
  const sp = new URLSearchParams(search); // /search?category=Shirts
  const category = sp.get("category") || "all";

  useEffect(() => {
    const getProjectsByCategory = async () => {
      const res = await axios
        .get(
          `https://api.aghayev-marjanscy.pl/api/projects/search?category=${category}`
        )
        .catch((err) => console.log(err));
      const data = await res.data;
      return data;
    };
    getProjectsByCategory().then((data) =>
      setProjects(data.categoriesProjects)
    );
  }, [category]);

  return (
    <div className="base">
      <ImageList gap={12} className="image-list-grid-template">
        {projects &&
          projects.map((project) => (
            <Card key={project._id} style={{ maxHeight: "550px" }}>
              <Link
                className="check"
                to={`/projects/${project._id}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ImageListItem
                  className="showTitle"
                  key={project._id}
                  sx={{ height: "100% !important", width: "100% !important" }}
                >
                  <img
                    // className="gallery-image"
                    src={`${project.images[0]}`}
                    alt={project.title}
                    loading="lazy"
                    style={{ cursor: "pointer" }}
                  />
                  <ImageListItemBar
                    className="showProjectTitle"
                    title={project.title}
                  />
                </ImageListItem>
              </Link>
            </Card>
          ))}
      </ImageList>
    </div>
  );
};

export default Category;
