import { Container, Stack, Typography, Button } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import axios from "axios";
import { Store } from "../Store";
import { useNavigate } from "react-router-dom";

const ProjectDetails = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [project, setProject] = useState();
  const [carouselImages, setCarouselImages] = useState();

  const deleteProject = async () => {
    const res = await axios
      .delete(`https://api.aghayev-marjanscy.pl/api/projects/${id}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };

  const handleDelete = () => {
    deleteProject().then(navigate("/"));
  };

  const handleEdit = () => {
    navigate(`/projects/edit/${id}`);
  };

  useEffect(() => {
    const getProject = async () => {
      const res = await axios
        .get(`https://api.aghayev-marjanscy.pl/api/projects/${id}`)
        .catch((err) => console.log(err));
      const data = await res.data;
      return data;
    };
    getProject().then(function (data) {
      setProject(data.project);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const setImages = async () => {
      const res = await axios
        .get(`https://api.aghayev-marjanscy.pl/api/projects/${id}`)
        .catch((err) => console.log(err));
      const data = await res.data;
      const carouselImages = data?.project.images.map((imageSrc) => ({
        src: imageSrc,
      }));
      // console.log(carouselImages);
      return carouselImages;
    };
    setImages().then((data) => setCarouselImages(data));
    // eslint-disable-next-line
  }, []);

  return (
    <Container sx={{ pt: 5 }}>
      <div className="carousel-container">
        {carouselImages && (
          <Carousel images={carouselImages} hasThumbnails={false} />
        )}
      </div>
      <Stack>
        <Typography paddingTop={"32px"} textAlign="left">
          Nazwa: {project?.title}
        </Typography>
        <Typography textAlign="left">Program: {project?.program}</Typography>
        <Typography textAlign="left">
          Powierzchnia: {project?.surface}
        </Typography>
        <Typography textAlign="left">
          Lokalizacja: {project?.location}
        </Typography>
        <Typography textAlign="left">Inwestor: {project?.investor}</Typography>
        <Typography textAlign="left">Zespół: {project?.team}</Typography>
        <Typography textAlign="left">
          Status Projektu: {project?.projectStatus}
        </Typography>
        <Typography textAlign="left">Kategoria: {project?.category}</Typography>
        <Typography textAlign="left" paddingBottom={"32px"}>
          Rok: {project?.year}
        </Typography>
        <Typography textAlign="justify">
          <div dangerouslySetInnerHTML={{ __html: project?.description }}></div>
        </Typography>
      </Stack>
      {userInfo && (
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Button onClick={handleDelete}>USUŃ</Button>
          <Button onClick={handleEdit}>Edytuj</Button>
        </Stack>
      )}
    </Container>
  );
};

export default ProjectDetails;
